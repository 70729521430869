import React from 'react';

import './home.scss';
import artists from '../../data/artists';
import ArtistTileList from '../../components/ArtistTileList';

class HomePage extends React.Component {
    render () {
        return (
            <div className="page-wrapper">
              <main className="main-content">
                <img src="/assets/logo-text.png" className="main-logo" />

                <div class="button-padding">
                  <a href="mailto:mike@mikechadwickmgmt.com">
                    <button className="button-normal">
                      <img src="/assets/envelope.svg" className="button-icon" />
                      <span>mike@mikechadwickmgmt.com</span>
                    </button>
                  </a>
                </div>
              </main>
              <aside className="main-sidebar">
                <h4 className="main-sidebar-heading">Current Artists</h4>
                <ArtistTileList artists={artists}/>
              </aside>
            </div>
        );
    }
};

export default HomePage;
