import React from 'react';

import './ArtistTileList.scss';

class ArtistTileList extends React.Component {
  constructor () {
    super();
    this.renderArtistTile = this.renderArtistTile.bind(this);
  }

  renderArtistTile (artist, index) {
    const onClick = () => artist.siteUrl
      ? window.open(artist.siteUrl)
      : null;

    const style = {
      backgroundImage: `url('${artist.photoUrl}')`
    };

    return (
      <div key={index} className="artist-tile" style={style} onClick={onClick} title={artist.photoCaption ? artist.photoCaption : ''}>
        <div className="artist-tile-inner">
          <h1>{artist.name}</h1>
        </div>
      </div>
    );
  }

  render () {
    const { artists } = this.props;

    return (
      <div className="artist-tile-list">
        {artists.map(this.renderArtistTile)}
      </div>
    );
  }
};

export default ArtistTileList;
