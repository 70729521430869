const artists = [
  {
    name: 'Snarky Puppy',
    siteUrl: 'http://snarkypuppy.com/',
    photoUrl: 'http://snarkypuppy.com/media/aboutHeader.jpg',
    photoCaption: 'Photo by Stella K'
  },
  {
    name: 'David Crosby',
    siteUrl: 'https://davidcrosby.com',
    photoUrl: '/assets/artists/david-crosby.jpg',
    photoCaption: 'Photo by Anna Webber'
  },
  {
    name: 'Bill Laurance',
    siteUrl: 'https://billlaurance.com/',
    photoUrl: 'https://files.cargocollective.com/c454093/bl-hero-home-bg.jpg'
  },
  {
    name: 'Bokanté',
    siteUrl: 'http://bokante.com',
    photoUrl: 'http://bokante.com/assets/assets-2018/images/band.jpg',
    photoCaption: 'Photo by Stella K'
  }
];

export default artists;
